import { Injectable, NgZone } from '@angular/core';
import { BackgroundGeolocation } from '@ionic-native/background-geolocation/ngx';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { interval } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DeviceOrientation, DeviceOrientationCompassHeading } from '@ionic-native/device-orientation/ngx';

import { Platform } from '@ionic/angular';
import { Const } from './const';
import { StorageWrapper } from './storage-wrapper';
import { Events } from './events';
import { Environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class LocationTracker {

  private watch: any;
  private dummyWatch: any;
  private _isActive: boolean;

  constructor(public zone: NgZone,
    public platform: Platform,
    public backgroundGeolocation: BackgroundGeolocation,
    public geolocation: Geolocation,
    public events: Events,
    public deviceOrientation: DeviceOrientation,
    public storageWrapper: StorageWrapper
  ) {
    console.log('LocTr const');
    this.inactive();
    this.setHeading();
  }

  private gpsData = { 
    lat: Environment.mapInitialStateLat,
    lng: Environment.mapInitialStateLng, 
    accuracy: 0, 
    heading: 0 };

  isActive(): boolean { return this._isActive; }
  getLat(): number { return this.gpsData.lat; }
  getLng(): number { return this.gpsData.lng; }
  getAccuracy(): number { return this.gpsData.accuracy; }
  getHeading(): number { return this.gpsData.heading; }

  private active() {
    this._isActive = true;
    this.dummyStopTracking(); //実際のGPSが利用可能な状態ならばダミーGPSは使用しない
  }

  private inactive() {
    this._isActive = false;
    this.dummyStartTracking();
  }

  private setHeading() {
    if (!this.platform.is('cordova')) { return; }

    this.deviceOrientation.watchHeading().subscribe((data: DeviceOrientationCompassHeading) => {
      this.gpsData.heading = data.magneticHeading;
    });
  }

  startTracking() {
    console.log('startTracking');

    if (!this.platform.is('cordova')) {
      this.webStartTracking();
      return;
    }

    // Background Tracking
    let config = {
      desiredAccuracy: 0,
      stationaryRadius: 20,
      distanceFilter: 10,
      interval: 2000
    };
    this.backgroundGeolocation.configure(config).then((location) => {
      // Run update inside of Angular's zone
      this.zone.run(() => {
        this.active();
        this.gpsData.lat = location.latitude;
        this.gpsData.lng = location.longitude;
        this.gpsData.accuracy = location.accuracy;
        this.events.publish(Const.USER_GPS_LOCATION, this.gpsData);
      });

    }, (err) => { console.log(err); });

    // Turn ON the background-geolocation system.
    this.backgroundGeolocation.start();

    // Foreground Tracking
    let options = {
      frequency: 2000,
      enableHighAccuracy: true
    };
    this.watch = this.geolocation.watchPosition(options).pipe(filter((p: any) => p.code === undefined)).subscribe((position: Geoposition) => {

      // Run update inside of Angular's zone
      this.zone.run(() => {
        this.active();
        this.gpsData.lat = position.coords.latitude;
        this.gpsData.lng = position.coords.longitude;
        this.gpsData.accuracy = position.coords.accuracy;
        this.events.publish(Const.USER_GPS_LOCATION, this.gpsData);
      });
    });
  }

  /** 
   * GPSが測位できていない場合の代理関数。初期値のGeolocation情報を返却する。
   * GPSが測位できない場合でもNIMOが動作するようにイベントを送り続ける必要がある。 
   */
  private dummyStartTracking() {
    this.dummyWatch = interval(2000).subscribe(() => {
      this.storageWrapper.getUserId().then((userId) => {
        if (userId == null) {
          return; //初回ログイン時はuserIdが不明なため、無視する
        }

        this.storageWrapper.getTripOrigin().then((tripOrigin) => {
          if (tripOrigin != null) { //tripOrigin情報が参照可能な場合のみ利用する
            this.gpsData.lat = tripOrigin.lat;
            this.gpsData.lng = tripOrigin.lng;
          }
          this.events.publish(Const.USER_GPS_LOCATION, this.gpsData);
        });
      });
    });
  }

  /** 開発時のエミュレータ用関数。navigator.geolocationを使用する. */
  private webStartTracking() {
    if (navigator.geolocation == null) { return; }

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    this.watch = navigator.geolocation.watchPosition((position) => {
      this.active();
      this.gpsData.lat = position.coords.latitude;
      this.gpsData.lng = position.coords.longitude;
      this.gpsData.accuracy = position.coords.accuracy;
      this.events.publish(Const.USER_GPS_LOCATION, this.gpsData);
    }, () => {
    }, options);
  }

  stopTracking() {
    console.log('stopTracking');

    if (!this.platform.is('cordova')) {
      this.webStopTracking();
      return;
    }

    if (this.watch == null) { return; }

    this.inactive();
    this.backgroundGeolocation.finish();
    this.watch.unsubscribe();
  }

  private dummyStopTracking() {
    if (this.dummyWatch == null) { return; }
    this.dummyWatch.unsubscribe();
  }

  private webStopTracking() {
    if (this.watch == null) { return; }
    this.inactive();
    navigator.geolocation.clearWatch(this.watch);
  }
}
