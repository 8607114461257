import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ControlOvserver{

  //車両情報を取得するAPIを呼ぶかどうかのFlag
  private postVehicleApiObcerver: Subscription;

  constructor(
  ) {}


  /** 車両情報を取得するAPIのInterval情報を保管 */
  public setPostVehiclesApiObserver(updated: Subscription){
    this.postVehicleApiObcerver = updated;
  }

  /** 車両情報を取得するAPIのInterval情報をクリア */
  public clearPostVehiclesApivObserver(){
    if(this.postVehicleApiObcerver){
      if(!this.postVehicleApiObcerver.closed){
        this.postVehicleApiObcerver.unsubscribe();
      }
    }
    this.postVehicleApiObcerver = null;
  }
}
