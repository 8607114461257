/* eslint-disable @typescript-eslint/naming-convention */
import { MaintUser } from './../providers/servicer/models/maint-user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { Environment } from '../../environments/environment';

import { Station } from './servicer/models/station';
import { UserLoginV2ApiResponse } from './servicer/models/user-login-v2-api-response';
import { UserOption } from './servicer/models/user-option';
import { Cargo } from './servicer/models/cargo';
import { RoutingAdditionalInfo } from './servicer/models/routing-additional-info';
import { UserStationsV2ApiResponse } from './servicer/models/user-stations-v2-api-response';
import { UserVehiclesV2ApiResponse, UserVehiclesInfoV2ApiResponse } from './servicer/models/user-vehicles-v2-api-response';
import { UserPuStationCodesV2ApiResponse } from './servicer/models/user-pu-station-codes-v2-api-response';
import { UserDoStationCodesV2ApiResponse } from './servicer/models/user-do-station-codes-v2-api-response';
import { Const } from './const';
import { UserOptionItemNameType } from 'src/app/types/user-option-item-name-type';
import { UserAccoountServiceProvider } from 'src/app/providers/servicer/user-account-service';
import { GenericItem } from 'src/app/providers/servicer/models/generic-item';

import { TransitionStatusService } from './transition-status.service';

@Injectable({ providedIn: 'root' })
export class HttpRequestor {

  /** ログイン時のユーザオプション. */
  userOption: UserOption;

  /** ステーションの情報. */
  station = {
    /** ステーションのリスト. */
    stations: [] as Station[],
    /** 必須ステーション. */
    requiredStation: null as Station,
    /** 必須ステーション範囲（単位 : メートル）. */
    requiredStationRadiusDistance: null as number,
    /** 自宅のステーション. */
    homeStation: null as Station,
    /** プライベートステーションのリスト. */
    privateStations: [] as Station[]
  };

  private base_url = Environment.baseUrl;

  constructor(
    public httpClient: HttpClient,
    private userAccoountServiceProvider: UserAccoountServiceProvider,
    public transitionStatus: TransitionStatusService
  ) {}


  /**ログインのリクエスト.
   *
   * @param loginId ログインID
   * @param passwd パスワード
   * @param language 言語
   * @param app_version appバージョン
   * @param callback コールバック
   * @param auto 自動ログイン
   */
  postLoginRequest(
    loginId: string,
    passwd: string,
    language: string,
    app_version: string,
    callback: any,
    auto: boolean
  ) {
    const url = this.base_url + 'login';
    this.userOption = null;

    // POSTする際のパラメータ値
    const postParams =
      'login_id=' + loginId +
      '&password=' + passwd +
      '&language=' + language +
      '&app_version=' + app_version;

    let result: UserLoginV2ApiResponse;

    this.postRequestObservable(url, postParams)
      .pipe(map((response: UserLoginV2ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }

        result = response;
        this.userOption = result.option;
        this.transitionStatus.setRequest(this.userOption);

        return response;
      }))
      .pipe(mergeMap(() => this.postStationsRequest(result.user_id.toString())))
      .subscribe(
        () => this.execCallback(callback, result, 0, -1, auto),
        () => this.execCallback(callback, '', -1, -1, auto)
      );
  }

  /**ユーザステーション一覧取得.
   * @param userId ユーザID
   * @returns Observable<UserStationsV2ApiResponse>
   */
  postStationsRequest(
    userId: string
  ): Observable<UserStationsV2ApiResponse> {
    const url = this.base_url + 'stations';

    // POSTする際のパラメータ値
    const postParams = 'user_id=' + userId;

    return this.postRequestObservable(url, postParams)
      .pipe(map((response: UserStationsV2ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }

        this.setStation(response.stations);

        return response;
      }));
  }

  /**[ユーザ]近地乗車ステーション一覧取得（v2/user/puStationCodes）.
   * @param userId ユーザID
   * @param lat 緯度
   * @param lon 経度
   * @returns Observable<UserPuStationCodesV2ApiResponse>
   */
   postPuStationCodesRequest(userId: string, lat: number, lon: number ): Observable<UserPuStationCodesV2ApiResponse> {
    const url = this.base_url + 'puStationCodes';

    // POSTする際のパラメータ値
    const postParams =
      'userId=' + userId +
      '&lat=' + lat +
      '&lon=' + lon;

    return this.postRequestObservable(url, postParams)
      .pipe(map((response: UserPuStationCodesV2ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }
        return response;
      }));
  }

  /*** [ユーザ]降車ステーション一覧取得（v2/user/doStationCodes）.
   * @param userId ユーザID
   * @param lat 緯度
   * @param lon 経度
   * @returns Observable<UserDoStationCodesV2ApiResponse>
   */
   postDoStationCodesRequest(userId: string, puStationCode: string ): Observable<UserDoStationCodesV2ApiResponse> {
    console.log('postDoStationCodesRequest puStationCode' + puStationCode);
    const url = this.base_url + 'doStationCodes';

    // POSTする際のパラメータ値
    const postParams =
      'userId=' + userId +
      '&puStationCode=' + puStationCode;

    return this.postRequestObservable(url, postParams)
      .pipe(map((response: UserDoStationCodesV2ApiResponse) => {
        if (response.result !== Const.HTTP_RESPONSE_RESULT_SUCCESS) {
          throw response.message;
        }
        return response;
      }));
  }

  // 車両検索のリクエスト
  postSearchRequest(
    user_id: string,
    lat: string,
    lon: string,
    user_number: string,
    child_count: string,
    adult_count: string,
    cargoCount: string,
    dest_lat: string,
    dest_lon: string,
    language: string,
    additionalInfo: RoutingAdditionalInfo,
    callback: any
  ) {
    const url = this.base_url + 'vehicleSearch';
    const userNum = user_number ? user_number : 0;
    const cargoCnt = cargoCount ? cargoCount : 0;
    const additionalInfoJson = JSON.stringify(this.convertAdditionalInfo(additionalInfo));

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&user_number=' + userNum +
      '&child_count=' + child_count +
      '&adult_count=' + adult_count +
      '&cargoCount=' + cargoCnt +
      '&dest_lat=' + dest_lat +
      '&dest_lon=' + dest_lon +
      '&language=' + language +
      '&additionalInfo=' + encodeURIComponent(additionalInfoJson) +
      '&guidance_destination=false';

    this.postRequest(url, postParams, callback, -1, false);
  }
  // 配車指示のリクエスト
  postDispatchRequest(
    user_id: string,
    vehicle_id: string,
    lat: string,
    lon: string,
    accuracy: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'dispatchRequest';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&accuracy=' + accuracy +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  // 配車確認のリクエスト
  postDispatchConfirmationRequest(
    user_id: string,
    language: string,
    callback: any,
    lat?: number,
    lon?: number,
    accuracy?: number
  ) {
    const url = this.base_url + 'dispatchConfirmation';

    // POSTする際のパラメータ値
    let postParams =
      'user_id=' + user_id +
      '&language=' + language;

    if (lat != null && lon != null && accuracy != null) {
      postParams = postParams + '&lat=' + lat +
        '&lon=' + lon +
        '&accuracy=' + accuracy;
    }

    this.postRequest(url, postParams, callback, -1, false);
  }

  // 目的地到着のリクエスト
  postArrivedDestinationRequest(
    user_id: string,
    lat: string,
    lon: string,
    accuracy: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'arrivedDestination';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&accuracy=' + accuracy +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  // 配車キャンセルのリクエスト
  postDispatchCancelRequest(
    user_id: string,
    vehicle_id: string,
    lat: string,
    lon: string,
    accuracy: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'dispatchCancel';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&lat=' + lat +
      '&lon=' + lon +
      '&accuracy=' + accuracy +
      '&language=' + language;
    this.postRequest(url, postParams, callback, -1, false);
  }

  /**
   * 車両一覧取得.
   *
   * @param userId ユーザID
   * @returns Observable<UserVehiclesV2ApiResponse>
   */
  postVehicleListRequest(
    userId: string,
  ): Observable<UserVehiclesV2ApiResponse> {
    const url = this.base_url + 'vehicles';

    // POSTする際のパラメータ値
    const postParams = 'user_id=' + userId;

    return this.postRequestObservable(url, postParams);
  }

  /**
   * 車両情報リスト取得
   *
   * @param userId ユーザID
   * @returns Observable<UserVehiclesInfoV2ApiResponse>
   */
  postVehiclesInfoRequest(
    userId: string,
  ): Observable<UserVehiclesInfoV2ApiResponse> {
    const url = this.base_url + 'vehiclesInfo';

    // POSTする際のパラメータ値
    const postParams = 'user_id=' + userId;

    return this.postRequestObservable(url, postParams);
  }

  // ユーザステータス到着変更のリクエスト
  postUserArrivedRequest(
    user_id: string,
    vehicle_id: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'arrived';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  // アンケート設問
  // question_id :
  //  1 (予約前キャンセルアンケート)
  //  2 (予約後キャンセルアンケート)
  //  3 (降車後アンケート)
  postQuestionReceiveServlet(
    user_id: string,
    question_id: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'questionReceive';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&question_id=' + question_id +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  // アンケート投稿
  // question_id :
  //  0 (予約前キャンセルアンケート)
  //  1 (予約後キャンセルアンケート)
  //  2 (降車後アンケート)
  postQuestionAnswerServlet(
    question_id: string,
    user_id: string,
    language: string,
    answers: string,
    callback: any
  ) {
    const url = this.base_url + 'questionAnswer';

    // POSTする際のパラメータ値
    const postParams =
      'question_id=' + question_id +
      '&user_id=' + user_id +
      '&language=' + language +
      '&answers=' + encodeURIComponent(answers);

    this.postRequest(url, postParams, callback, -1, false);
  }
  /*** アカウント情報を取得し、userOptionとstationのデータを再取込
   * @param userId ユーザID
   */
  public fetchUserOptionAndStation(userId: number): Observable<GenericItem<MaintUser>>{
    return this.userAccoountServiceProvider.get(userId).pipe(
      map((response)=>{
        this.userOption = {
          homeStationCode: response.item.options.find((v) => v.name === UserOptionItemNameType.HOME_STATION_CODE)?.value,
          privateStationCodes: response.item.options.find((v) => v.name === UserOptionItemNameType.PRIVATE_STATION_CODES)?.value.split(','),
          requestableReservation: response.item.options.find((v) => v.name === UserOptionItemNameType.REQUESTABLE_RESERVATION)?.value,
          requiredStationCode: response.item.options.find((v) => v.name === UserOptionItemNameType.REQUIRED_STATION_CODE)?.value,
          requiredStationRadiusDistance: Number(response.item.options.find(
            (v) => v.name === UserOptionItemNameType.REQUIRED_STATION_RADIUS_DISTANCE
          )?.value),
        }  as UserOption;
        return response;
      }),
      map((response)=>{
        this.postStationsRequest(userId.toString()).subscribe();
        return response;
      })
    );
  }

  // 予約確認
  postReservationConfirmRequest(
    user_id: string,
    language: string,
    callback: any
  ) {
    const url = this.base_url + 'reservationConfirm';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&language=' + language;

    this.postRequest(url, postParams, callback, -1, false);
  }

  //配車履歴取得
  postDispatchHistoryReauest(
    user_id: string,
    language: string,
    callback: any,
    start_dt?: string,
    end_dt?: string,
  ){
    const url = this.base_url + 'dispatchHistory';

    let postParams =
      'user_id=' + user_id +
      '&language=' + language;

    if (start_dt && end_dt) {
      postParams = postParams +
        '&start_dt=' + start_dt +
        '&end_dt=' + end_dt;
    }

    this.postRequest(url, postParams, callback, -1, false);
  }

  // BLE API
  postBoardingCheckRequest(
    user_id: string,
    vehicle_id: string,
    mp_id: string,
    token: string,
    callback: any
  ) {
    const url = this.base_url + 'boardingCheck';

    // POSTする際のパラメータ値
    const postParams =
      'user_id=' + user_id +
      '&vehicle_id=' + vehicle_id +
      '&mp_id=' + mp_id +
      '&token=' + token;

    this.postRequest(url, postParams, callback, -1, false);
  }

  postPlacePredictions(
    user_id: string,
    input: string,
    callback: any,
    option?: { //候補地としての優先度を上げたい地域を指定
      range?: {
        lat: number;
        lng: number;
        radius: number;
      };
      language?: string;
    }
  ) {
    const url: string = this.base_url + 'placePredictions';

    let param: string =
      'user_id=' + user_id +
      '&input=' + input;
    param = this.setPlacePredictionsParamOption(param, option);

    this.postRequest(url, param, callback, 0, false);
  }

  setPlacePredictionsParamOption(param: string, option: any): string {
    if (option == null) {
      return param;
    }

    if (option.range != null &&
      option.range.lat != null &&
      option.range.lng != null &&
      option.range.radius != null) {
      param = param + '&lat=' + option.range.lat + '&lng=' + option.range.lng + '&radius=' + option.range.radius;
    }

    if (option.language != null && option.language !== '') {
      param = param + '&language=' + option.language;
    }

    return param;
  }

  postPlaceDetails(user_id: string, place_id: string, callback: any, language?: string) {
    const url: string = this.base_url + 'placeDetails';

    let param: string =
      'user_id=' + user_id +
      '&place_id=' + place_id;
    if (language != null) { param = param + '&language=' + language; }

    this.postRequest(url, param, callback, 0, false);
  }

  postReverseGeocode(user_id: string, lat: number, lng: number, callback: any, language?: string) {
    const url: string = this.base_url + 'reverseGeocode';

    let param: string =
      'user_id=' + user_id +
      '&lat=' + lat +
      '&lng=' + lng;
    if (language != null) { param = param + '&language=' + language; }

    this.postRequest(url, param, callback, 0, false);
  }

  postUserSettings(user_id: string, language: string, fcm_token: string, callback: any) {
    const url: string = this.base_url + 'settings';

    const param: string =
      'user_id=' + user_id +
      '&language=' + language +
      '&fcm_token=' + fcm_token;

    this.postRequest(url, param, callback, 0, false);
  }

  // ポストリクエスト実行
  postRequest(
    url: string,
    postParams: string,
    callback: any,
    req_sts: number,
    auto: any
  ) {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const options = {
      headers: header,
      withCredentials: true
    };

    console.log('send:' + postParams);

    this.httpClient
      .post(url, postParams, options)
      .toPromise()
      .then((data) => {
        try {
          if (data) {
            console.log('post: ' + JSON.stringify(data));
          }
          this.execCallback(callback, data, 0, req_sts, auto);
        } catch (e) {
          console.log(e);
          this.execCallback(callback, data, -1, req_sts, auto);
        }
      })
      .catch((err) => {
        console.log('post error: ' + err);
        this.execCallback(callback, '', -1, req_sts, auto);
      });
  }

  /*** POSTリクエスト実行（Observableで返却）.
   * @param url URL
   * @param params パラメータ
   * @returns Observable
   */
  postRequestObservable<V>(
    url: string,
    params: string
  ): Observable<V> {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const options = {
      headers: header,
      withCredentials: true
    };

    return this.httpClient.post<V>(url, params, options);
  }



  private execCallback(callback, res, code, req_sts, auto) {
    try {
      callback(res, code, req_sts, auto);
    } catch (e) {
      console.log('execCallback error.');
      console.log(e);
    }
  }

  /*** additionalInfoの変換(null判定).
   * @param additionalInfo 荷物の追加情報
   */
   private convertAdditionalInfo(additionalInfo: RoutingAdditionalInfo): RoutingAdditionalInfo {
    const routingAdditionalInfo: RoutingAdditionalInfo = {
      cargoes: null,
      puStationCode: null,
      doStationCode: null
    };

    if (!additionalInfo) {
      return null;
    }
    if(additionalInfo.cargoes){
      const cargoExist: Cargo[] = additionalInfo.cargoes.filter(cargo => cargo.cargoId || cargo.ownerId);
      if (cargoExist.length > 0) {
        routingAdditionalInfo.cargoes = cargoExist;
      }
    }
    routingAdditionalInfo.doStationCode = additionalInfo.doStationCode;
    routingAdditionalInfo.puStationCode = additionalInfo.puStationCode;

    return routingAdditionalInfo;
  }

  /*** ステーション関連の情報を設定します.
   * @param originalStations オリジナルのステーションのリスト
   * @returns ステーションのリスト
   */
  private setStation(originalStations: Station[]) {
    this.station.stations = originalStations;

    this.userOption = this.transitionStatus.getRequest();
    if (!this.userOption) {
      return;
    }

    const stations = this.toStationsSetCode(originalStations);

    this.station.requiredStation = this.toStation(this.userOption.requiredStationCode, stations);
    this.station.requiredStationRadiusDistance = this.userOption.requiredStationRadiusDistance;
    this.station.homeStation = this.toStation(this.userOption.homeStationCode, stations);
    this.station.privateStations = this.toStations(this.userOption.privateStationCodes, stations);
  }

  /*** ステーションのリストにコードを設定します.
   * @param originalStations オリジナルのステーションのリスト
   * @returns コードを設定したステーションのリスト
   */
  private toStationsSetCode(originalStations: Station[]): any {
    if (!originalStations) {
      return [];
    }

    const stations = [];

    originalStations.forEach((v) => {
      stations[v.code] = v;
    });

    return stations;
  }

  /*** ステーションのリストに変換します.
   * @param stationCodes ステーションコードのリスト
   * @param originalStations ステーションのリスト
   * @returns ステーションのリスト
   */
  private toStations(stationCodes: string[], originalStations: Station[]): any {
    if (!stationCodes) {
      return [];
    }

    const stations = [];

    stationCodes.forEach((stationCode) => {
      const station = originalStations[stationCode];

      if (station) {
        stations.push(station);
      }
    });
    return stations;
  }

  /*** ステーションに変換します.
   * 以下の場合、null を返却します.
   * - ステーションコードがない
   * - ステーションのリストにステーションコードがない
   *
   * @param stationCode ステーションコード
   * @param stations ステーションのリスト
   * @returns ステーション
   */
  private toStation(stationCode: string, originalStations: Station[]): Station {
    if (!stationCode) {
      return null;
    }

    const station = originalStations[stationCode];

    if (!station) {
      return null;
    }

    return station;
  }
}
