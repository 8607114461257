import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

import { TranslateModule } from '@ngx-translate/core';

import { Events } from '../services/events';
import { StorageWrapper } from '../services/storage-wrapper';
import { LocationTracker } from '../services/location-tracker';
import { HttpRequestor } from '../services/http-requestor';
import { BluetoothProvider } from '../services/bluetooth-provider';
import { LanguageSetup } from '../services/language-setup';
import { UserStatusManager } from '../services/user-status-manager';
import { NotificationOption } from '../services/notification-option';

@NgModule({
  imports: [
  ],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        Storage,
        StorageWrapper,
        Events,
        LocationTracker,
        HttpRequestor,
        BluetoothProvider,
        LanguageSetup,
        UserStatusManager,
        NotificationOption
      ]
    };
  }
}
